@import '~antd/dist/antd.css';

.logo {
  float: left;
  margin-left: ;
  width: 140px;
}

.logo>img{
    max-width:100%;
    max-height:100%;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
.menuNav{
  border:0px solid #fff;
  display: inline; 
  float:left;
}
ul{
  float: right;
  background-color: white;
  list-style-type: none;
  padding: 0px;
}

.navLink{
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom:0px;
  color:black;
  background-color: white;
  list-style-type: none;
  position: relative;

}

.navLinkText{
  color:black;
}

.navLink:hover::after{
  width: 100%;
  left: 0;
}
li{
  text-align: center;
  font-size: 12px;
}
.navLink:after{
  content: '';
  position: absolute;
  width: 0px;
  height: 1px;
  left: 50%;
  bottom:0;
  background-color: #1890ff;
  transition: all ease-in-out .2s;
}
.navLink:hover {
  color:#1890ff;
  background-color:#e0f0ff;
  border: none;
}
.white-header{
    background-color:white;
    z-index: 1000;
}
ul:before{
  content:attr(aria-label);
  /* font-size:120%;
  font-weight:bold;
  margin-left:-15px; */
}
.menuNav>.navLink{
  display: none;
  position: absolute;
  left: -100%;
  top:0;
  width: 100%;
  z-index: 1;
} 
.menuNav:hover .navLink {
  display: block;
}
.outlined {
  display:none;
}
.menuNav:hover> .outlined {
  display:inline-block;
}
.navLink.menuNav::after{
  display: none;
}